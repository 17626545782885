import React from "react"
import {
    HeaderText,
    BodyText,
    WhenEventParagraph,
    ToogleEventInfo
} from "../Partials/TextPieces"
import styled from "@emotion/styled"
import { BreakLine } from "../../styles/globalCss"
import LogoComponent from "./LogoComponent"
import Spacer from "../Partials/Spacer"
import { useState } from "react"
import { fluid } from "../../styles/mixins"
import { breakpoints } from "../../styles/sizes"

const SingleEvent = ({
    where,
    when,
    eventName,
    endingDate,
    headline,
    eventInfo
}) => {
    const [showMoreInfo, toggleMoreInfo] = useState(false)

    const handleClick = () => {
        toggleMoreInfo(!showMoreInfo)
    }

    const today = new Date()
    const tomorrow = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1
    )

    const eventDate = endingDate ? new Date(endingDate) : null

    let displayEvent = false

    if (eventDate > tomorrow || eventDate === null) {
        displayEvent = true
    }

    return (
        displayEvent && (
            <>
                <EventContainer>
                    <Spacer size="small" />
                    {where && <LogoComponent where={where} />}
                    <HeaderText copy={eventName} smallH2 />
                    {when && <WhenEventParagraph>{when}</WhenEventParagraph>}
                    {headline && (
                        <BodyText copy={headline} padding noTopMargin />
                    )}
                    {eventInfo && (
                        <>
                            <Spacer size="xsmall" />
                            {showMoreInfo && (
                                <>
                                    <EventDetailsContainer>
                                        <BodyText
                                            copy={
                                                eventInfo.childMarkdownRemark
                                                    .html
                                            }
                                        />
                                    </EventDetailsContainer>
                                    <Spacer size="xsmall" />
                                </>
                            )}
                            <ToogleEventInfo onClick={handleClick}>
                                {showMoreInfo
                                    ? "Close Event Details"
                                    : "View Event Details"}
                            </ToogleEventInfo>
                        </>
                    )}
                    <Spacer size="small" />
                </EventContainer>
                <BreakLine />
            </>
        )
    )
}

export default SingleEvent

const EventContainer = styled.div`
    ${fluid("padding-left", 10, 200, breakpoints[0], breakpoints[3])}
    ${fluid("padding-right", 10, 200, breakpoints[0], breakpoints[3])}
`
const EventDetailsContainer = styled.div``
