import React from "react"
import Img from "gatsby-image"
import { fontFamily } from "../../styles/fonts/fontFamily"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const LogoComponent = ({ where }) => {
    const { logo, nameOfPlace, textColor } = where

    return (
        logo && (
            <LogoContainer>
                <ImgContainer>
                    <div>
                        <Img fluid={logo.fluid} alt={logo.description} />
                    </div>
                </ImgContainer>
                <PlaceName color={textColor}>
                    {nameOfPlace.toUpperCase()}
                </PlaceName>
            </LogoContainer>
        )
    )
}

export default LogoComponent

const PlaceName = styled.p`
    font-family: ${fontFamily.accent};
    font-size: 14px;
    letter-spacing: 3.11px;
    text-align: center;
    padding-top: 20px;
    ${({ color }) => css`
        color: ${color};
    `}
`

const LogoContainer = styled.div`
    margin-bottom: 30px;
    display: inline-block;
`
const ImgContainer = styled.div`
    width: 40px;
    height: 40px;
    margin: auto;
`
