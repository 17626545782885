import React from "react"
import SingleEvent from "./SingleEvent"

const EventList = ({ events = [] }) => {
    return events.map(
        ({
            id,
            primaryEventInformation,
            secondaryEventInformation,
            endDate,
            eventDetails,
            eventTitle,
            place
        }) => (
            <SingleEvent
                key={id}
                when={primaryEventInformation}
                where={place}
                endingDate={endDate}
                headline={secondaryEventInformation}
                eventInfo={eventDetails}
                eventName={eventTitle}
            />
        )
    )
}

export default EventList
