import React from "react"
import ContactUsForm from "../components/Forms/ContactUsForm"
import SEO from "../components/utils/seo"
import { MaxWidthInnerContainer } from "../styles/globalCss"
import { graphql } from "gatsby"
import { get } from "lodash"
import { BodyText } from "../components/Partials/TextPieces"
import BackgroundImage from "../components/Partials/BackgroundImage"
import EventList from "../components/Calendar/EventList"
import Spacer from "../components/Partials/Spacer"

const Calendar = ({ data }) => {
    const { fluid: bkgImg } = get(
        data,
        "contentfulCalendarPage.backgroundImage",
        {}
    )
    const { description: bkgImgDescription } = get(
        data,
        "contentfulCalendarPage.backgroundImage"
    )
    const disclaimer = get(
        data,
        "contentfulCalendarPage.disclaimer.childMarkdownRemark.html"
    )
    const { events } = get(data, "contentfulCalendarPage", [])

    return (
        <>
            <SEO title="Calendar" />
            <BackgroundImage bkgImg={bkgImg} description={bkgImgDescription} />
            <MaxWidthInnerContainer>
                <BodyText copy={disclaimer} padding marginTopAndBottom />
                <EventList events={events} />
                <Spacer size="small" />
            </MaxWidthInnerContainer>
            <ContactUsForm bkgImg={bkgImg} description={bkgImgDescription} />
        </>
    )
}

export default Calendar

export const query = graphql`
    query {
        contentfulCalendarPage {
            backgroundImage {
                description
                fluid(maxWidth: 1440, quality: 75) {
                    ...GatsbyContentfulFluid
                }
            }
            disclaimer {
                childMarkdownRemark {
                    html
                }
            }
            events {
                endDate(formatString: "YYYY-MM-DDTHH:mm:ssZ")
                eventDetails {
                    childMarkdownRemark {
                        html
                    }
                }
                eventTitle
                id
                place {
                    logo {
                        description
                        fluid(maxWidth: 40, quality: 75) {
                            ...GatsbyContentfulFluid
                        }
                    }
                    nameOfPlace
                    textColor
                }
                primaryEventInformation
                secondaryEventInformation
            }
        }
    }
`
